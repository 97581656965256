import Form from 'vform'

export default {
  data: () => ({
    form: new Form({
      phone_number: '',
      password: '',
      chap_challenge: '',
      chap_id: '',
      error: '',
      ga_ssid: '',
      host_ip: '',
      ip: '',
      link_login: '',
      link_login_only: '',
      link_logout: '',
      link_orig: '',
      link_orig_esc: '',
      link_status: '',
      logged_in: '',
      mac: '',
      mac_esc: '',
      router_id: '',
      server_address: '',
      session_id: '',
      trial: '',
      username: '',
      vlan_id: '',
    }),
    queryParams: {},
  }),
  mounted() {
    const routeQueryParams = this.$route.query

    Object.keys(routeQueryParams).forEach(key => {
      const formKey = this.hyphenToUnderscore(key)
      if (this.form[formKey] !== undefined) {
        this.form[formKey] = routeQueryParams[key]
        this.updateQueryParams(key, routeQueryParams[key]) // Use the original key here
        if (formKey === 'router_id') {
          this.$store.dispatch('setRouterId', routeQueryParams[key])
        }
        if (formKey === 'mac') {
          this.$store.dispatch('setMacAddress', routeQueryParams[key])
        }
      }
    })
  },
  methods: {
    hyphenToUnderscore(str) {
      return str.replace(/-/g, '_')
    },
    updateQueryParams(key, value) {
      // Use Vue's reactivity system to update queryParams
      this.$set(this.queryParams, key, value)
    },
  },
}
